import React from "react"
import "../styles/logo.css"
import "../components/Layout"
import Navigation from "../components/Navigation"
import Includes from "../components/Includes"
import Footer from "../components/Footer"
import Header from "../components/Header"
import Partners from "../components/Partners"


export default function partnerwithus () {
  return (<>
            <Header/>
            <Navigation/>
            <br></br>
            <Partners/>
            <Includes/>
            <Footer/>

        
  </>)
}