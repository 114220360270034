
import React from "react"
import { Link } from "gatsby"


export default function Partners() {
    return (
        <section id="portfolio" class="clearfix">
            <div class="container" data-aos="fade-up">

                <header class="section-header">
                    <br></br>
                    <br></br>
                    <br></br>
                    {/* <h3 class="section-title">Our Partners</h3> */}


                    <h3 class="section-title">Technology Inspired.<b style={{ color: "#EE4141", }}> Growth Driven.</b></h3>

                </header>
                <br></br>
                <p style={{ textAlign: "left", }}>
                    Our undeterred commitment to innovation and entrepreneurial spirit is actualized through our many ecosystem partners and sponsors, including business support providers, leading professional firms, government, and educational organizations.
                </p>
                <p style={{ textAlign: "left", }}>
                    We provide networking opportunities, access to follow-on finance, government incentives, local startup resources, and business services to help entrepreneurs and companies build viable ventures.
                </p>
                <p style={{ textAlign: "left", }}>
                    In addition, we stimulate strategic connections between our clients and partners to accelerate their growth, which is the core value of our company.

                </p>
                <p style={{ textAlign: "left", }}>
                    We are always eager to connect with new corporate partners and find ways to collaborate and support partnership goals.
                </p>

                {/* <div class="section-header" style={{ textAlign: "center", }}>
                    <br></br>
                    <h5 class="section-title"><b style={{ textAlign: "center", }}>Click on the button below to get started</b></h5>
                </div> */}
                <br></br>
               

                <div className="row row-eq-height justify-content-center">
                    <div className="col-lg-4 mb-4">
                        <center>
                            <Link to="/contact" className="page-button">
                                <b>Connect With Us</b>
                            </Link>
                        </center>
                    </div>
                </div>
                <br></br>
                {/* <div class="row" data-aos="fade-up" data-aos-delay="100">
                    <div class="col-lg-12">
                        <ul id="portfolio-flters">
                            <li data-filter="*" class="filter-active">All</li>
                            <li data-filter=".filter-app">Category1</li>
                            <li data-filter=".filter-card">Category2</li>
                            <li data-filter=".filter-web">Category3</li>
                        </ul>
                    </div>
                </div>

                <div class="row portfolio-container" data-aos="fade-up" data-aos-delay="200">

                    <div class="col-lg-4 col-md-6 portfolio-item filter-app">
                        <div class="portfolio-wrap">
                            <img src="assets/img/portfolio/app1.jpg" class="img-fluid" alt="" />
                            <div class="portfolio-info">
                                <h4><a href="portfolio-details.html">App 1</a></h4>
                                <p>App</p>
                                <div>
                                    <a href="assets/img/portfolio/app1.jpg" data-gallery="portfolioGallery" title="App 1" class="portfolio-lightbox link-preview"><i class="bi bi-plus"></i></a>
                                    <a href="portfolio-details.html" class="link-details" title="More Details"><i class="bi bi-link"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 portfolio-item filter-web">
                        <div class="portfolio-wrap">
                            <img src="assets/img/portfolio/web3.jpg" class="img-fluid" alt="" />
                            <div class="portfolio-info">
                                <h4><a href="portfolio-details.html">Web 3</a></h4>
                                <p>Web</p>
                                <div>
                                    <a href="assets/img/portfolio/web3.jpg" class="portfolio-lightbox link-preview" data-gallery="portfolioGallery" title="Web 3"><i class="bi bi-plus"></i></a>
                                    <a href="portfolio-details.html" class="link-details" title="More Details"><i class="bi bi-link"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 portfolio-item filter-app">
                        <div class="portfolio-wrap">
                            <img src="assets/img/portfolio/app2.jpg" class="img-fluid" alt="" />
                            <div class="portfolio-info">
                                <h4><a href="portfolio-details.html">App 2</a></h4>
                                <p>App</p>
                                <div>
                                    <a href="assets/img/portfolio/app2.jpg" class="portfolio-lightbox link-preview" data-gallery="portfolioGallery" title="App 2"><i class="bi bi-plus"></i></a>
                                    <a href="portfolio-details.html" class="link-details" title="More Details"><i class="bi bi-link"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 portfolio-item filter-card">
                        <div class="portfolio-wrap">
                            <img src="assets/img/portfolio/card2.jpg" class="img-fluid" alt="" />
                            <div class="portfolio-info">
                                <h4><a href="portfolio-details.html">Card 2</a></h4>
                                <p>Card</p>
                                <div>
                                    <a href="assets/img/portfolio/card2.jpg" class="portfolio-lightbox link-preview" data-gallery="portfolioGallery" title="Card 2"><i class="bi bi-plus"></i></a>
                                    <a href="portfolio-details.html" class="link-details" title="More Details"><i class="bi bi-link"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 portfolio-item filter-web">
                        <div class="portfolio-wrap">
                            <img src="assets/img/portfolio/web2.jpg" class="img-fluid" alt="" />
                            <div class="portfolio-info">
                                <h4><a href="portfolio-details.html">Web 2</a></h4>
                                <p>Web</p>
                                <div>
                                    <a href="assets/img/portfolio/web2.jpg" class="portfolio-lightbox link-preview" data-gallery="portfolioGallery" title="Web 2"><i class="bi bi-plus"></i></a>
                                    <a href="portfolio-details.html" class="link-details" title="More Details"><i class="bi bi-link"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 portfolio-item filter-app">
                        <div class="portfolio-wrap">
                            <img src="assets/img/portfolio/app3.jpg" class="img-fluid" alt="" />
                            <div class="portfolio-info">
                                <h4><a href="portfolio-details.html">App 3</a></h4>
                                <p>App</p>
                                <div>
                                    <a href="assets/img/portfolio/app3.jpg" class="portfolio-lightbox link-preview" data-gallery="portfolioGallery" title="App 3"><i class="bi bi-plus"></i></a>
                                    <a href="portfolio-details.html" class="link-details" title="More Details"><i class="bi bi-link"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 portfolio-item filter-card">
                        <div class="portfolio-wrap">
                            <img src="assets/img/portfolio/card1.jpg" class="img-fluid" alt="" />
                            <div class="portfolio-info">
                                <h4><a href="portfolio-details.html">Card 1</a></h4>
                                <p>Card</p>
                                <div>
                                    <a href="assets/img/portfolio/card1.jpg" class="portfolio-lightbox link-preview" data-gallery="portfolioGallery" title="Card 1"><i class="bi bi-plus"></i></a>
                                    <a href="portfolio-details.html" class="link-details" title="More Details"><i class="bi bi-link"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 portfolio-item filter-card">
                        <div class="portfolio-wrap">
                            <img src="assets/img/portfolio/card3.jpg" class="img-fluid" alt="" />
                            <div class="portfolio-info">
                                <h4><a href="portfolio-details.html">Card 3</a></h4>
                                <p>Card</p>
                                <div>
                                    <a href="assets/img/portfolio/card3.jpg" class="portfolio-lightbox link-preview" data-gallery="portfolioGallery" title="Card 3"><i class="bi bi-plus"></i></a>
                                    <a href="portfolio-details.html" class="link-details" title="More Details"><i class="bi bi-link"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 portfolio-item filter-web">
                        <div class="portfolio-wrap">
                            <img src="assets/img/portfolio/web1.jpg" class="img-fluid" alt="" />
                            <div class="portfolio-info">
                                <h4><a href="portfolio-details.html">Web 1</a></h4>
                                <p>Web</p>
                                <div>
                                    <a href="assets/img/portfolio/web1.jpg" class="portfolio-lightbox link-preview" data-gallery="portfolioGallery" title="Web 1"><i class="bi bi-plus"></i></a>
                                    <a href="portfolio-details.html" class="link-details" title="More Details"><i class="bi bi-link"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div> */}

            </div>
        </section>
    )
}


